import { template as template_677741ef074046a8af688ef963bb4541 } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
const ActionList = template_677741ef074046a8af688ef963bb4541(`
  {{#if @postNumbers}}
    <div class="post-actions" ...attributes>
      {{icon @icon}}
      {{#each @postNumbers as |postNumber|}}
        <a href="{{@topic.url}}/{{postNumber}}">#{{postNumber}}</a>
      {{/each}}
    </div>
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActionList;
