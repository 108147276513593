import { template as template_6f6bb9c805fb49d1af64bcf3f89acbef } from "@ember/template-compiler";
const FKText = template_6f6bb9c805fb49d1af64bcf3f89acbef(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
